body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.butt1 {
  background-color: #f1ca48e8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  text-align: left;
}

.container {
  width: 1200px;
  margin: auto;
  border: 0px solid orange;
  text-align: center;
}

.weekcontainer {
  margin: 10px;
  padding-left: 7px;
  padding-right: 7px;
  border: 1px solid #142738;
  background-color: #232e30e8;
  border-radius: 5px;
}

.tablecontainer {
  margin: auto;
  width: 1200px;
  border: 0px solid orange;
  text-align: center;
  
}

.playercontainer {
  margin: 10px;
  color: #799ab6;
  border: 1px solid #142738;
  background-color: #232e30e8;
  border-radius: 5px;
  float:left; 
}

.playerdiv {
  float:left; 
}

.playerdivstats {
  float:left;
  padding:20px; 
}

.statbold {
  font-weight: bold;
  color: #f5eed6;
  font-size: 1.2em;
}

.grid td {
  width: 25px;
  height: 25px;
  padding: 2px 2px;
  border: 0px solid rgb(252, 190, 76);
  text-align: center;
  float: left;
  margin: 5px;
  font-size: 14px;
  border-radius: 2px;
}

.grid td:hover {
  opacity: .7;
  cursor: pointer;
}

.grid2 {
  width: 100%;
  border-collapse: collapse;
  background-color: #aeebf3e8;
  border-radius: 5px;
}

.grid2 th {
  padding: 10px 10px 5px 10px;
  background-color: #0c1f31;
  color: #e6f2f3;
}

.grid3 th {
  padding: 10px 10px 5px 10px;
  background-color: #f5e488e8;
  color: #10273a;
}


.grid2 td {
  padding: 2px 2px 10px 10px;
  border-bottom: 1px solid #799ab6;
  text-align: center;
}

.gridwager {
  padding: 2px 2px 10px 10px;
  border-bottom: 1px solid #799ab6;
  text-align: right;
}

.gridwagerleft {
  padding: 2px 2px 10px 10px;
  border-bottom: 1px solid #799ab6;
  text-align: left;
}

.helmet {
  width: 40px;
  padding-bottom: 0px;
  margin-bottom: -10px;
}

.playerPic {
  height: 200px;
}

.show {
  padding: 2px 10px;
  border: 1px solid rgb(17, 31, 44);
  background-color: #0ebad1e8;
  text-align: center;
  float: left;
  margin: 5px;
  font-size: 14px;
  border-radius: 2px;
}

.show:hover {
  background-color: orange;
  cursor: pointer;
}

.reserved {
  background: orange;
  color: #fff;
  display: inline-block;
}

.reserved::after {
  background: red;
}

.tempreserved {
  background: rgb(194, 3, 51);
  color: #fff;
  display: inline-block;
}

.available::after {
  background: rgba(53, 209, 14, 0.911);
}

.available {
  background: #0ebad1e8;
  color: #333;
}

.covid {
  background: #6b1101;
  color: #333;
}

.ns {
  background: #62796c;
  color: #627466;
}

.note1 {
  font-style: italic;
}

.unk {
  background: #b2c0c2e8;
  color: #333;
}

.sold {
  background: #000000;
  color: #fff;
}

.stage {
  width: 1170px;
  height:50px;
  margin: auto;
  background: #81a1a7;
  text-align: center;
  margin: 5px;
  font-size: 30px;
  font-weight: bold;
  border-radius: 5px;
  color: #accfd1;
  border: 0px solid orange;
  display: inline-block;
}

.clearall {
  clear: both;
}

.winnings {
  text-align: right;
  font-size: 2em;
  font-weight: bold;
  color: #5fc202;
}